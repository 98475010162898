import React, { Component } from 'react';
import './Game.css';
import Message from './Message'
import InfoBox from './InfoBox'
import socketIOClient from "socket.io-client";
// import queryString from 'query-string';
import { trackPromise } from 'react-promise-tracker';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import GameMenu from '../GameMenu/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faSmile } from '@fortawesome/free-solid-svg-icons'
import InnerImageZoom from 'react-inner-image-zoom';
import './InnerImageZoomStyles.min.css';
import OnImagesLoaded from 'react-on-images-loaded';

var socket;
var token;
var gameId = "";

class Game extends Component {
    
    constructor(props) {
        super(props);

        this.chatHistoryRef = React.createRef();

        //console.log(process.env.REACT_APP_SOCKETIO);

           //Get gameId from querystring
          // const values = queryString.parse(this.props.location.search);
           //console.log(values.g);
        //    gameId = values.g;

        this.state = {
            message: "",
            isTyping: false,
            wait: 0,
            hasFetched: false, // flag for API
            data: [],
            currentChatNo: 1,
            currentStep: 0,
            infoLinks: {links: [], linksEn: []},
            lastHint: false,
            gameStatus: -1,
            hcpMessage: "",
            lang: 'sv'
        }

        if(!this.props.location.state || !this.props.location.state.gameId)
        {
            this.props.history.push("/");
            return undefined;
        }
        gameId = this.props.location.state.gameId.toUpperCase();

        this.handleGameStateFromApi = this.handleGameStateFromApi.bind(this);
        this.fetchUpdateFromApi = this.fetchUpdateFromApi.bind(this);

        //Auth, get token from api
        trackPromise(
            fetch("/api/auth?gameId=" + gameId, {method: 'post'})
                .then((res) => {
                    if(!res.ok) throw new Error(res.status);
                    else return res.json();
                })
                .then(res => {
                    //console.log(res);
                    token = res.accessToken;
                    
                    //Get current game state and store in reactState data
                    trackPromise(
                        fetch("/api/game?g=" + gameId, {
                            method: "GET",
                            headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                            'Authorization': `Bearer ${token}`
                            }
                        })
                            .then(resp => resp.json())
                            .then(gameState => {
                            if (gameState) {
                                //Got gameState from api
                                this.handleGameStateFromApi(gameState);

                                if(this.state.gameStatus !== 4)
                                {
                                    //Connect to socketIo
                                    socket = socketIOClient(process.env.REACT_APP_SOCKETIO, {query: {token: token }, withCredentials: true});

                                    //When socketio reconnects
                                    socket.io.on("reconnect", (a) => {
                                        // console.log("Reconnecting");
                                        this.fetchUpdateFromApi();
                                        this.scrollToBottomWithDelay();
                                    });

                                    //Recieve message from api
                                    socket.on("InMsg", msg => {
                                        // console.log(msg);
                                        this.setState(state => {
                                            const data = [...state.data, msg];
                                            return {
                                                data
                                            };
                                        });
                                        this.setState({ isTyping: msg.isTyping });

                                        if(msg.wait === 0 && this.state.wait > 0)
                                        {
                                            //Clear wait message in inputbox if someone else has sent wait ok message
                                            this.setState({ message: ''});
                                        }

                                        this.setState({ wait: msg.wait });
                                        this.setState({ currentStep: msg.step })
                                        if(msg.wait > 0)
                                        {
                                            this.setState({ message: this.getWaitMessage(msg.wait)});
                                        }
                                        if(msg.lastHint){
                                            this.setState({ lastHint: true });
                                        }
                                        else{
                                            this.setState({ lastHint: false });
                                        }
                                        if(msg.gameStatus)
                                        {
                                            if(msg.gameStatus === 4) {
                                                setTimeout(function() { //Delay end message
                                                    this.setState({ gameStatus: msg.gameStatus });
                                                    socket.disconnect();
                                                }.bind(this), 8000)
                                            }
                                            else {
                                                this.setState({ gameStatus: msg.gameStatus });
                                            }
                                        }

                                        this.scrollToBottomWithDelay();
                                    });
                                }
                                this.setState({ hasFetched: true });

                            } else {
                                console.log(gameState);
                                console.log("Error");
                            }
                        })
                    );
                                        
                    trackPromise(
                        fetch("/api/gameLinks", {
                            method: "GET",
                            headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/json',
                            'Authorization': `Bearer ${token}`
                            }
                        })
                            .then(resp => resp.json())
                            .then(links => {
                                this.setState({ infoLinks: links })
                            }
                        )
                    );
        }));

    }
    
    fetchUpdateFromApi() {
        fetch("/api/game?g=" + gameId, {
            method: "GET",
            headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            'Authorization': `Bearer ${token}`
            }
        })
            .then(resp => resp.json())
            .then(gameState => {
            if (gameState) {
                //Got gameState from api
                this.handleGameStateFromApi(gameState);
                this.scrollToBottom();
            }
        });
    }

    handleGameStateFromApi(gameState) {
        // console.log(gameState);
        this.setState({ 
            data: gameState.messages,
            gameStatus: gameState.status,
            lang: gameState.lang
        });
        this.setState({ currentStep: ((gameState.currentStep >= 0) ? gameState.currentStep : 0)})

        //Handle wait for next location
        if(gameState.messages.length > 0 && gameState.messages[gameState.messages.length - 1].wait > 0)
        {
            this.setState({ wait: gameState.messages[gameState.messages.length - 1].wait });
            this.setState({ message: this.getWaitMessage(gameState.messages[gameState.messages.length - 1].wait)});
        }
        else
        {
            if (this.state.wait && this.state.wait > 0)
            {
                this.setState({ wait: 0 });
                this.setState({ message: ""});
            }
        }

        //Handle last hint and chatNo
        if(gameState.messages.length > 0)
        {
            var lastMsg = gameState.messages[gameState.messages.length - 1];
            this.setState({ lastHint: lastMsg.lastHint });
            this.setState({ currentChatNo: lastMsg.chatNo });
            this.setState({ isTyping: lastMsg.isTyping });
        }
    }

    getWaitMessage = (waitStatus) => {
        switch (waitStatus) {
            case 1:
                switch (this.state.currentStep) {
                    case 0:
                        return this.lang("Okej, vi är här nu.", "Okay, at 4B now");
                    case 2:
                        return this.lang("Vi är framme nu", "We're here now");
                    case 3:
                        return this.lang("Framme!", "We're here");
                    case 5:
                        return this.lang("Nu är vi här", "We have arrived");
                    case 6:
                        return this.lang("Nu är vi framme!", "We're here!");
                    default:
                        return this.lang("Vi är framme nu", "We're here!");
                }
            case 2:
                return this.lang("Okej, vi ses där", "Okay, see you there");
            case 3:
                return this.lang("Nu är vi på plats", "We're there now");
            default:
                break;
        }
    }

    componentWillUnmount() {
        // console.log("Disconnect");
        if(socket)
            socket.disconnect();
        gameId = "";
    }

    componentDidMount() {
        this.scrollToBottom();

    }
      
    componentDidUpdate() {
        // console.log("componentDidUpdate");
        // this.scrollToBottom();
    }

    createMessageList = () => {
        return (
            <ul>
              {
                this.state.data.map((value, index) => {
                    return (this.state.gameStatus !== 4 || index > this.state.data.length-4) && <Message key={index} data={value} onImgLoad={this.scrollToBottomWithDelay} lang={this.state.lang}></Message>
                })
              }
            </ul>
          )
    }

    scrollToBottom = () => {
        this.messagesEnd.scrollIntoView({ behavior: "smooth" });
        // console.log("scrolltobottom");
    }

    scrollToBottomWithDelay = () => {
        //Add a delay to allow phone keyboard to open before scrolling down
        setTimeout(this.scrollToBottom, 200);
        // console.log("scrolltobottomDelay");
    }

    sendMessageSocket = (msgText, msgStatus) => {

        var value = {
            direction: 2,
            message: msgText,
            status: msgStatus,
            chatNo: this.state.data[this.state.data.length -1].chatNo
        };

        if (socket.connected)
        {
            socket.emit("msg", value);
            this.setState({ message: "" });
            this.scrollToBottomWithDelay();
        }
        else
        {
            alert("Saknar kontakt med servern. Kontrollera din Internetanslutning och ladda om sidan.");
        }
    }
    
    //When player clicks send
    sendMessage = () => {
        var status = 1;
        if(this.state.wait === 1 || this.state.wait === 3)
        {
            status = 3;
        }
        if(this.state.wait === 2)
        {
            status = 4;
        }
        this.sendMessageSocket(this.state.message, status);
    }
    
    requestHint = () => {
        this.setState({ menuIsOpen: false });
        this.sendMessageSocket(this.state.lastHint ? this.lang("Vi behöver lösningen", "We need the solution") : this.lang("Vi behöver en ledtråd", "We need a hint"), 2);
    }

    showHcpInfo = (txt) => {
        this.setState({ menuIsOpen: false });
        this.setState({ hcpMessage: txt });
    }

    clearHcpInfo = () => {
        this.setState({ hcpMessage: "" });
    }

    onChangeValue = event => {
        this.setState({ message: event.target.value });
    };

    inputOnFocus = () => {
        //Only scroll to bottom if player hasn't scrolled manually
        let height = this.chatHistoryRef.current.scrollHeight - this.chatHistoryRef.current.clientHeight;
        let scrollPos = this.chatHistoryRef.current.scrollTop;
        if(height - scrollPos < 100)
        {
            this.scrollToBottomWithDelay();
        }
    }

    handleEnter=(event)=> {
        if (event.keyCode === 13) {
            this.sendMessage();
        }
    }

    newChat = () => {
        this.sendMessage();
    }

    lang = (sv, en) => {
        return this.state.lang === 'sv' ? sv : en;
    }

    render() {
        // if your component is while fetching shows a loading to the user
        if(!this.state.hasFetched) return <div ref={(el) => { this.messagesEnd = el; }}>...</div>;

        return (
            <div className="container clearfix">
                <GameMenu requestHint={this.requestHint} showHcpInfo={this.showHcpInfo} lastHint={this.state.lastHint} hintEnabled={this.state.wait===0 && !this.state.isTyping} currentStep={this.state.currentStep} infoLinks={this.lang(this.state.infoLinks.links, this.state.infoLinks.linksEn)} lang={this.state.lang} />
                <LoadingIndicator/>
                <div className="chat">

                    {this.state.hcpMessage !== "" && (
                        <InfoBox text={this.state.hcpMessage} close={this.lang("Stäng", "Close")} onClose={this.clearHcpInfo}></InfoBox>
                    )}

                    <div className="chat-history" ref={this.chatHistoryRef}>
                        { this.createMessageList() }

                        {this.state.gameStatus === 4 && (
                        
                            <div className="end-message">
                                
                                <OnImagesLoaded
                                    onLoaded={this.scrollToBottom}>
                                    <InnerImageZoom src={`images/${this.lang("slutbild_swe.jpg", "slutbild_eng.jpg")}`} />
                                </OnImagesLoaded>
                                <br/>
                                <div className="header">
                                    {this.lang("Tack för att ni spelade Kidnappad!", "Thank you for playing Kidnappad!")}
                                </div>
                                <p>{this.lang("Håll Umeå rent genom att slänga pappren i närmaste återvinningskärl/papperskorg.", "Keep Umeå clean and please throw all the papers in the nearest recycling bin.")}</p>
                                {this.state.lang === "sv" && (
                                    <p>Läs gärna mer om några av <a href="https://www.molinders.se/sevardheter-kidnappad" target="blank">sevärdheterna</a> längs vägen.</p>
                                )}
                                <p>{this.lang("Om ni vill ha fler mysterier kan ni gå in på", "If you want more mysteries you can visit")} <a href="https://www.molinders.se" title="Molinders Mysterium" target="blank">www.molinders.se</a> {this.lang("och boka ett escape room eller läsa om våra andra stadsspel på ", "and book an escape room or check out our other cityquests at ")} <a href="https://www.stadsspel.se" title="Molinders Stadsspel" target="blank">www.stadsspel.se</a>.</p>
                                <hr/>
                                {this.state.lang === "sv" && (
                                    <p>
                                        Tyckte ni om det här spelet blir vi glada om ni lämnar en recension på <a href="https://www.tripadvisor.se/Attraction_Review-g189818-d15111037-Reviews-Molinders_Mysterium-Umea_Vasterbotten_County.html" target="blank">Tripadvisor</a>, <a href="https://www.facebook.com/molindersmysterium" target="blank">Facebook</a> eller <a href="https://www.google.com/search?q=Molinders+Mysterium" target="blank">Google</a>. 
                                        Skriv gärna att det är vårt stadsmysterium ni gjort. 
                                        <br />
                                        På <a href="https://www.instagram.com/molindersmysterium" target="blank">Instagram</a> kan ni tagga oss med @molindersmysterium eller #molindersmysterium men helst inga spoilers <FontAwesomeIcon icon={faSmile} />
                                    </p>
                                )}
                                {this.state.lang === "en" && (
                                    <p>
                                        If you liked this game we would be very grateful if you gave us a review on <a href="https://www.tripadvisor.se/Attraction_Review-g189818-d15111037-Reviews-Molinders_Mysterium-Umea_Vasterbotten_County.html" target="blank">Tripadvisor</a>, <a href="https://www.facebook.com/molindersmysterium" target="blank">Facebook</a> or <a href="https://www.google.com/search?q=Molinders+Mysterium" target="blank">Google</a>. 
                                        Please write that it is the city quest you played.
                                        <br />
                                        If you post a picture on <a href="https://www.instagram.com/molindersmysterium" target="blank">Instagram</a> you can tag @molindersmysterium or #molindersmysterium but please avoid spoilers <FontAwesomeIcon icon={faSmile} />
                                    </p>
                                )}
                            </div>
                        
                        )}

                        {this.state.isTyping && (
                            <div className="typing-indicator">
                                <span className='typing-text'>{this.lang("Skriver", "Typing")}</span>
                                <span className='typing-dot'></span>
                                <span className='typing-dot'></span>
                                <span className='typing-dot'></span>
                            </div>
                        )}

                        <div style={{ float:"left", clear: "both" }}
                            ref={(el) => { this.messagesEnd = el; }}>
                        </div>
                    </div>
                    <div className="chat-message clearfix">
                        <button onClick={this.sendMessage} disabled={!this.state.message || this.state.isTyping}><FontAwesomeIcon icon={faPaperPlane} /></button>
                        <span>
                            <input 
                                name="message-to-send" 
                                id="message-to-send" 
                                type="text" 
                                disabled={!(this.state.wait === 0) || this.state.gameStatus === 4 || this.state.isTyping} 
                                value={this.state.message} 
                                onChange={this.onChangeValue} 
                                onFocus={this.inputOnFocus} 
                                onKeyDown={(e) => this.handleEnter(e) }
                                maxLength="50"
                                autoComplete="off"
                            />
                        </span>
                    </div>

                </div>
            </div>
        );
    }
}

export default Game;
