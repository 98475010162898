import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Start.css';
import queryString from 'query-string';

const Start = () => {
    const [gameId, setGameId] = useState("");     

    const location = useLocation();

    useEffect(() => {
        const values = queryString.parse(location.search);
        if(values.g != null)
        {
            setGameId(values.g.toUpperCase());
        }
    }, [location.search])

    const onChangeValue = event => {
        setGameId(event.target.value);
    };
    
    return (
        <div className="divStart">
            <div className="header"><img src="images/kidnappad_logo2.png" alt="Kidnappad"/></div>
            Ange kod / Enter code:<br/>
            <input
                className="codeInput"
                onChange={onChangeValue} 
                value={gameId} 
            />
            <br/>
            <LoginButton gameId={gameId}/>

            {/* <p><a href="https://www.molinders.se/kidnappad" target="blank">Kidnappad</a></p> */}
        </div>
    );
}

function LoginButton(props) {
  let navigate = useNavigate();

  function handleClick() {
    navigate("/game", {state: {gameId: props.gameId }});
  }

  return (
    <button 
        className="loginButton"
        type="button" 
        onClick={handleClick}
        disabled={!props.gameId}>
        OK
    </button>
  );
}

export default Start;